<template>
  <div>

    <div class="content-header mb-4"></div>

    <b-container>
      <div class="page-title">
        <h1>Unsere Brands - Entdecke großartige Film- und Serienwelten</h1>
      </div>

      <div class="row">
        <div v-for="movie in movies" v-bind:key="movie.$id" class="col-sm-6 col-md-4">
          <div v-if="movie.teaser !== null && movie.image_id !== null">
            <div class="card h-100 mb-4">
              <img v-if="movie.image_id !== null" :alt="movie.name"
                   :src="(movie.image_id === null) ? '' : movie.image_id" class="img-fluid"/>
              <div class="card-body">
                <h5 class="card-title"><strong>{{ movie.name }}</strong></h5>
                <p class="card-text">
                  {{ movie.teaser }}
                </p>
                <router-link :to="movie.alias" class="btn btn-primary">Mehr erfahren <i
                    class="ml-1 fa-light fa-arrow-right"></i></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </b-container>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
      movies: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const apiUrl = 'https://paramount.de/json/franchise/api'
      const {data, error} = await this.$http.get(apiUrl)

      if (error) {
        console.log(error)
      } else {
        console.log(data.documents)
        this.movies = data.documents
      }
    }
  }
}
</script>

<style>
.content-header {
  display: block;
  background: url('https://paramount.de/franchise/img/paramount-brands-background.jpg') no-repeat left center;
  background-size: cover;
  min-height: 420px;
}

.card {
  transition: box-shadow .2s;
}

.card:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
</style>