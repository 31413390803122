import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
  id: 'UA-34139717-2',
  router
});

Vue.prototype.$http = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
